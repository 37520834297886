import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'
import { CustomUrlMapperSymbol, ICustomUrlMapper, UrlMappingsKeys } from 'feature-custom-url-mapper'
import { BuildUrlOptions } from '@wix/url-mapper-utils'

export type buildUrlMessageData = {
	key: UrlMappingsKeys
	itemData: Record<string, string>
	options?: BuildUrlOptions
}

export type HandlerResponse = Promise<{ url: string }>

export const BuildUrlHandler = withDependencies(
	[optional(CustomUrlMapperSymbol)],
	(customUrlMapper?: ICustomUrlMapper): TpaHandlerProvider => {
		return {
			getTpaHandlers() {
				return {
					async buildUrl(compId, msgData: buildUrlMessageData) {
						const { key, itemData, options } = msgData

						const urlMappings = customUrlMapper?.urlMappings

						const { buildUrl } = await import(
							'@wix/url-mapper-utils' /* webpackChunkName: "url-mapper-utils" */
						)

						const url = buildUrl(urlMappings, key, itemData, options)

						return { url }
					},
				}
			},
		}
	}
)
