import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'
import { CustomUrlMapperSymbol, ICustomUrlMapper } from 'feature-custom-url-mapper'

export type MessageData = { url: string; options: Record<string, string> }
export type HandlerResponse = Promise<{ segments: Record<string, string> }>

export const GetUrlSegmentsHandler = withDependencies(
	[optional(CustomUrlMapperSymbol)],
	(customUrlMapper: ICustomUrlMapper | undefined): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				async getUrlSegments(compId, msgData: MessageData) {
					const { url, options } = msgData
					const urlMappings = customUrlMapper?.urlMappings

					const { getUrlSegments } = await import(
						'@wix/url-mapper-utils' /* webpackChunkName: "url-mapper-utils" */
					)

					const segments = getUrlSegments(urlMappings, url, options)

					return { segments }
				},
			}
		},
	})
)
