import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	ITpaPopup,
	TpaFeatureState,
	TpaMasterPageConfig,
	TpaPageConfig,
	TpaPopupProps,
	TpaPopupRegistry,
} from './types'
import {
	BrowserWindowSymbol,
	contextIdSymbol,
	FeatureStateSymbol,
	IPageDidMountHandler,
	IPageDidUnmountHandler,
	IPropsStore,
	IStructureAPI,
	MasterPageFeatureConfigSymbol,
	PageFeatureConfigSymbol,
	pageIdSym,
	Props,
	SiteFeatureConfigSymbol,
	StructureAPI,
} from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { name } from './symbols'
import { computeTpaPopupStyleOverrides, isFullScreen } from './utils/tpaStyleOverridesBuilder'
import { createPromise, isSSR, runtimeTpaCompIdBuilder } from '@wix/thunderbolt-commons'
import { IFeatureState } from 'thunderbolt-feature-state'
import _ from 'lodash'
import { ISiteScrollBlocker, SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'
import {
	name as tpaCommonsName,
	TpaCommonsSiteConfig,
	ITpaSrcBuilder,
	TpaSrcBuilderSymbol,
	ITpaContextMapping,
	TpaContextMappingSymbol,
} from 'feature-tpa-commons'
import { PlatformPubsubSymbol, IPubsub } from 'feature-platform-pubsub'

export const TPA_POPUP_COMP_ID_PREFIX = 'tpapopup'

export const TpaPopupFactory = withDependencies(
	[
		Props,
		StructureAPI,
		named(FeatureStateSymbol, name),
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(MasterPageFeatureConfigSymbol, name),
		named(PageFeatureConfigSymbol, name),
		SessionManagerSymbol,
		BrowserWindowSymbol,
		SiteScrollBlockerSymbol,
		pageIdSym,
		contextIdSymbol,
		TpaSrcBuilderSymbol,
		TpaContextMappingSymbol,
		PlatformPubsubSymbol,
	],
	(
		props: IPropsStore,
		structureAPI: IStructureAPI,
		featureState: IFeatureState<TpaFeatureState>,
		{ isMobileView }: TpaCommonsSiteConfig,
		{ masterPageTpaComps }: TpaMasterPageConfig,
		{ widgets }: TpaPageConfig,
		sessionManager: ISessionManager,
		window: Window,
		siteScrollBlocker: ISiteScrollBlocker,
		pageId: string,
		contextId: string,
		tpaSrcBuilder: ITpaSrcBuilder,
		tpaContextMapping: ITpaContextMapping,
		pubsubFeature: IPubsub
	): ITpaPopup & IPageDidMountHandler & IPageDidUnmountHandler => {
		// keep opened popup in the page container scope while persistent popups are kept in feature state
		const openedPopups: TpaPopupRegistry = {}
		const { resolver: pageDidMountResolver, promise: pageDidMountPromise } = createPromise()

		const updatePersistentPopups = (persistentPopups: TpaPopupRegistry) => {
			featureState.update((currentState) => ({ ...currentState, tpaPopup: { persistentPopups } }))
		}

		const getPersistentPopups = (): TpaPopupRegistry =>
			_.get(featureState.get(), ['tpaPopup', 'persistentPopups'], {})

		const popupCompIdRegex = new RegExp(
			runtimeTpaCompIdBuilder.buildRuntimeCompId(`${TPA_POPUP_COMP_ID_PREFIX}-[0-9]+`, '.+')
		)

		return {
			pageDidMount() {
				pageDidMountResolver()
				sessionManager.addLoadNewSessionCallback(() => {
					Object.values(getPersistentPopups()).forEach(({ refreshPopUp }) => refreshPopUp())
					Object.values(openedPopups).forEach(({ refreshPopUp }) => refreshPopUp())
				})
			},
			pageDidUnmount() {
				Object.values(openedPopups).forEach(({ closePopup }) => {
					closePopup()
				})
			},
			isPopup(compId) {
				return popupCompIdRegex.test(compId)
			},
			openPopup(url, options, compId) {
				return new Promise(async (resolve) => {
					if (isSSR(window)) {
						// do not open popups in ssr even if requested from an OOI/wixCode
						return
					}
					await pageDidMountPromise
					const popupCompId = runtimeTpaCompIdBuilder.buildRuntimeCompId(
						`${TPA_POPUP_COMP_ID_PREFIX}-${Date.now()}`,
						compId
					)
					// in cases where openPopup is triggered with compId belonging to another container, use the container of the compId instead of the current
					const originContextId = compId ? structureAPI.getContextIdOfCompId(compId) || contextId : contextId
					tpaContextMapping.registerTpasForContext(originContextId, [popupCompId])
					const closePopup = (data: any) => {
						if (options.persistent) {
							const persistentPopups = getPersistentPopups()
							delete persistentPopups[popupCompId]
							updatePersistentPopups(persistentPopups)
						} else {
							delete openedPopups[popupCompId]
						}
						structureAPI.removeComponentFromDynamicStructure(popupCompId)
						siteScrollBlocker.setSiteScrollingBlocked(false, popupCompId)
						if (!options.persistent) {
							pubsubFeature.clearListenersByCompId(popupCompId)
						}
						resolve(data)
					}

					const styleOverrides = computeTpaPopupStyleOverrides(options, window, compId)
					const buildSrc = () => {
						const originTpaCompData = widgets[compId] || masterPageTpaComps[compId]
						return tpaSrcBuilder.buildSrc(popupCompId, pageId, originTpaCompData, url, {
							extraQueryParams: { origCompId: compId },
						})
					}

					const refreshPopUp = () => {
						props.update({
							[popupCompId]: { src: buildSrc() },
						})
					}

					const tpaProps: TpaPopupProps = {
						// put the original options and compId if we ever need to recalculate style later
						options,
						originCompId: compId,
						src: buildSrc(),
						styleOverrides,
						isBareTheme: options.theme === 'BARE',
						closePopup,
					}

					props.update({
						[popupCompId]: tpaProps,
					})

					await structureAPI.addComponentToDynamicStructure(popupCompId, {
						components: [],
						componentType: 'TPAPopup',
					})

					const popupRegistryData: TpaPopupRegistry['string'] = {
						isPersistent: options.persistent,
						closePopup,
						refreshPopUp,
					}
					if (popupRegistryData.isPersistent) {
						updatePersistentPopups({
							...getPersistentPopups(),
							[popupCompId]: popupRegistryData,
						})
					} else {
						openedPopups[popupCompId] = popupRegistryData
					}
					siteScrollBlocker.setSiteScrollingBlocked(
						isMobileView && isFullScreen(styleOverrides, window),
						popupCompId
					)
				})
			},
			closePopup(compId, onCloseMessage) {
				const popup = getPersistentPopups()[compId] || openedPopups[compId]
				if (popup) {
					popup.closePopup(onCloseMessage)
				}
			},
			getOpenedPopups() {
				return { ...getPersistentPopups(), ...openedPopups }
			},
		}
	}
)
